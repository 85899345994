.container-offline {
  font-family: Arial, sans-serif;
  background-color: #d7d7d7;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sub-container-offline {
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
}

.retry-button {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.retry-button:hover {
  opacity: 0.8;
}

.offline-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.offline-cont svg {
  margin-right: 10px;
}
