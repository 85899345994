.help_page {
  background-color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
 
}



.fa-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #000000;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding: 10px;
  color: #fff;
}


.contact-form{
  background: #fff;
  margin-top: 10%;
  margin-bottom: 5%;
  width: 70%;
}
.contact-form .form-control{
  border-radius:1rem;
}
.contact-image{
  text-align: center;
}
.contact-image img{
  border-radius: 6rem;
  width: 11%;
  margin-top: -3%;
  transform: rotate(29deg);
}

.contact-form form{
  padding: 14%;
}

.contact-form form .row{
  margin-bottom: -7%;
}

.contact-form h3{
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: #d7ba13;
}
.contact-form .btnContact {
  width: 50%;
  border: none;
  border-radius: 1rem;
  padding: 1.5%;
  background: #d7ba13;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.btnContactSubmit
{
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  color: #fff;
  background-color: #0062cc;
  border: none;
  cursor: pointer;
}