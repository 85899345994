.homepage {
  background-image: url("../../../../public/nicolas-peyrol-l2VmsBG8nPE-unsplash.png");
  background-size: cover;
  background-position: top;
  justify-content: center;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;

}

.borders {
  border-left:solid blanchedalmond 3px;
    border-right:solid blanchedalmond 3px;
    border-bottom:solid blanchedalmond 3px;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
}

.header__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    min-height: 100%;
    height: 100vh;
    position: relative;
    background-color: transparent;
}

.title__cover {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 2vw;
    font-weight: 800;
    font-family: 'Lexend Mega', sans-serif;
    color: #000;
}


.search {
  width: 15%;
  height: 10%;
  border-radius: 2em;
  overflow: hidden;
  font-size: 15px;
  display: flex;
  flex-flow: row nowrap;
}

.search:focus-within {
  box-shadow: 2px 2px 5px #000;
}

.search__filter,
.search__input {
  background-color: #fff;
  border: none;
  outline: none;
}

.search__filter {
  width: 100%;
  font-size: 1.5rem;
  color: gray;
  display: grid;
  place-content: center;
}

.scroll__down {
  text-align: center;
  position: absolute;
  margin-top: 90vh;
}

.scroll__down p {
  color: #fff;
  font-family: font-alethia-pro;
}

.filter__menu{
  position: absolute;
  display:inline-block;
  top: 70%;
  right: 20%;
  width:60%;
  padding: 3%;
  border-radius: 4px;
  background-color: #fff;
  z-index: 999999;
}

.filter__title{
  text-align: center;
  color: #000;
  font-family:font-alethia-pro;
}

.row_filter{
  text-align: center;
  color: #000;
  font-family:font-alethia-pro;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(253, 233, 13, 0.25) !important;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(253, 233, 13, 0.25) !important;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #FFD700 !important;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.form-check-input:checked{
  background-color: #FFD700 !important;
  border-color: #FFD700 !important;
}

.results{
  background-color: #FFC400 !important;
}

.results:hover {
  background-color: #dbc233 !important;
}


@media only screen and (max-width: 600px) {
  .search {
    font-size: 10px;
  }

  .search__filter {
    font-size: 1.5rem;
    right: 0.5em;
  }
}

@media only screen and (max-width: 800px) {
  .filter__menu{
    right: 10%;
    top: 65%;
    width:80%;
    padding: 5%;
  }

  .header__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    min-height: 100%;
    height: 90vh;
    position: relative;
    background-color: transparent;
}

  .search {
    width: 85%;
    height: 10%;
    border-radius: 2em;
    overflow: hidden;
    font-size: 15px;
    display: flex;
    flex-flow: row nowrap;
  }

  .search__filter {
    right: 0.5em;
    font-size: 1.5rem;
  }

  .scroll__down {
    text-align: center;
    position: absolute;
    margin-top: 70vh;
  }
}

@media only screen and (max-width: 820px) {
  .filter__menu{
    top: 63%;
  }
}


@media only screen and (max-width: 912px) {
  .filter__menu{
    top: 65%;
  }
}

@media only screen and (max-width: 540px) {
  .filter__menu{
    top: 66%;
  }
}