@import url("~leaflet/dist/leaflet.css");

.apartment_details {
  background-color: black;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.rdrCalendarWrapper .rdrDayDisabled {
  background-color: #d7d7d7;
  border-radius: 1px;
}

.apartment_details_content {
  padding: 3%;
}
.app_carousel {
  padding-top: 2%;
}

.app_details_carousel {
  width: 100%;
  height: 100%;
  padding: 3%;
}

.app_carousel {
  width: 50%;
  float: left;
}

.app_details {
  width: 50%;
  float: right;
  padding: 2%;
  color: white;
}

.app_details h1 {
  font-family: "Lexend Mega", sans-serif;
}

.app_details h5 {
  font-family: font-alethia-pro;
}

.app_details p {
  font-family: font-alethia-pro;
}

.app_description,
.all__details {
  text-align: left;
}

.custom-button {
  font-family: font-alethia-pro !important;
}

.services_details strong {
  text-align: left;
  font-family: font-alethia-pro;
}

.justify-content-end {
  display: flex;
  justify-content: right;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.pick_reservation {
  width: 100%;
}

.reservation__map {
  width: 60%;
  height: 47.6vh;
  float: left;
}

.date__range {
  width: 40%;
  float: right;
}

.reservation_details {
  width: 100%;
  height: 50vh;
  background-color: #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dates_reservation {
  margin-bottom: 3%;
}

.check-in {
  background-color: black;
  color: white;
  border-left: 3px solid #d7d7d7;
  border-right: 3px solid #d7d7d7;
  font-family: font-alethia-pro !important;
}
.check-out {
  background-color: black;
  color: white;
  border-left: 3px solid #d7d7d7;
  border-right: 3px solid #d7d7d7;
  font-family: font-alethia-pro !important;
}

.details_row {
  font-family: font-alethia-pro !important;
  text-align: center;
}

.custom-button-reservation-row {
  justify-content: space-around;
}

.custom-button-reservation {
  width: 130px !important;
  background-color: black !important;
  font-family: font-alethia-pro !important;
}

.reservation_details_content {
  width: 40%;
  height: 90%;
  padding: 3%;
}

.msl-wrp {
  width: 100% !important;
  color: black;
}

.msl-btn {
  color: beige !important;
}

@media only screen and (max-width: 768px) {
  .app_carousel {
    width: 100%;
  }
  .app_details {
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .reservation__map {
    width: 100%;
  }

  .date__range {
    width: 100%;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 850px) {
  .check-in {
    width: 100% !important;
    background-color: black;
    color: white;
    border-top: 6px solid #d7d7d7;
    font-family: font-alethia-pro !important;
  }
  .check-out {
    width: 100% !important;
    background-color: black;
    color: white;
    border-top: 6px solid #d7d7d7;
    font-family: font-alethia-pro !important;
  }

  .reservation_details {
    width: 100%;
    height: 85vh;
  }
}

@media only screen and (max-width: 350px) {
  .check-in {
    width: 100% !important;
    background-color: black;
    color: white;
    border-top: 6px solid #d7d7d7;
    font-family: font-alethia-pro !important;
    font-size: 10px;
  }
  .check-out {
    width: 100% !important;
    background-color: black;
    color: white;
    border-top: 6px solid #d7d7d7;
    font-family: font-alethia-pro !important;
    font-size: 10px;
  }

  .details_row {
    font-size: 13px;
  }
  .custom-button-reservation {
    font-size: 10px !important;
  }
}

.review-cards-cont {
  background-color: #000 !important;
}

.write-review-cont {
  position: sticky;
  top: 20px;
}

.review-card {
  background-color: #d7d7d7 !important;
}

.drop-toggle {
  position: absolute !important;
  bottom: 5px;
  right: 5px;
}

.add-review-btn,
.edit-btn {
  background-color: #d7d7d7 !important;
  outline: none !important;
  border-color: #000 !important;
  color: #000 !important;
}

.write-review-img {
  width: 5rem !important;
  height: 5rem !important;
}

.review-textArea {
  background-color: #d7d7d7 !important;
}

.modal-content {
  background-color: #000 !important;
  color: white !important;
}

.review-card {
  overflow-x: hidden;
}