@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #000;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.go-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  font-family: font-alethia-pro !important;
}

.go-home-space{
  position: absolute;
  height: 100%;
  width: 45%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: 0.8s ease-in-out;

}

.box {
  position: relative;
  width: 100%;
  max-width: 1020px;
  height: 670px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
}

.inner-box {
  position: absolute;
  width: calc(100% - 4.1rem);
  height: calc(100% - 4.1rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forms-wrap {
  position: absolute;
  height: 100%;
  width: 45%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: 0.8s ease-in-out;
}

.log-in-form {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  margin-top: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: opacity 0.02s 0.4s;
}

form.sign-up-form {
  opacity: 0;
  pointer-events: none;

}

.actual-form {
  top: 0;
  position: relative;
}


.heading h4 {
  font-size: 1.1rem;
  margin-top: -9px;
  letter-spacing: -0.5px;
  color: #000;
  font-family: font-alethia-pro !important;
  margin-top: 10px;
  margin-bottom: 20px
}

.heading h2 {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 0;
  font-weight: 400;
  font-family: "Spectral SC";
  color: #000;
}

.heading h6 {
  color: #bababa;
  font-weight: 400;
  font-size: 0.75rem;
  display: inline;
}

.toggle {
  color: #151111;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 500;
  transition: 0.3s;
}

.toggle:hover {
  color: #8371fd;
}

.input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
  text-align: left;
}

.input-field {
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: 1px solid #555;
  padding: 0;
  font-size: 0.95rem;
  color: #151111;
  transition: 0.4s;
}

.label-form {
  font-family: font-alethia-pro;
  font-size: 0.8rem;
}

.input-field.active {
  border-bottom-color: #151111;
  font-size: 0.1rem !important;
}

.input-field.active+label {
  font-size: 0.75rem;
  top: -2px;
}

.sign-btn {
  display: inline-block;
  width: 50%;
  height: 43px;
  background-color: #151111;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0.1rem;
  font-size: 1rem;
  margin-bottom: 2rem;
  transition: 0.3s;
  font-family: font-alethia-pro;
}

.sign-btn:hover {
  background-color: #575757;
}

.text {
  color: #bbb;
  font-size: 0.7rem;
}

.text a {
  color: #bbb;
  transition: 0.3s;
}

.text a:hover {
  color: #8371fd;
}

main.sign-up-mode form.log-in-form {
  opacity: 0;
  pointer-events: none;
}

main.sign-up-mode form.sign-up-form {
  opacity: 1;
  pointer-events: all;
}

.reset__options{
height: 20%;
}
.remember-me{
 width: 50%;
 float: left;
 text-align: left;
 font-size: 70%;
 font-family: font-alethia-pro;
}
.forgot-password {
  width: 50%;
  float: right;
  text-align: right;
  font-size: 70%;
  font-family: font-alethia-pro
}
main.sign-up-mode .forms-wrap {
  left: 55%;
}

main.sign-up-mode .carousel_login {
  left: 0%;
}

.carousel_login {
  position: absolute ;
  height: 100%;
  width: 55%;
  left: 45%;
  top: 0;
  background-image:url(../../../../public/login_image.png);
  background-size: cover;
  justify-content: center;
  border-radius: 2rem;
  display: grid;
  grid-template-rows: auto 1fr;
  padding-bottom: 2rem;
  overflow: hidden;
  transition: 0.8s ease-in-out;
}
.logo{
  width: 50%;
  height: 10%;
  margin-top:40%;
  margin-left: 25%;
}

.images-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.image {
  width: 100%;
  grid-column: 1/2;
  grid-row: 1/2;
  opacity: 0;
  transition: opacity 0.3s, transform 0.5s;
}

.img-1 {
  transform: translate(0, -50px);
}

.img-2 {
  transform: scale(0.4, 0.5);
}

.img-3 {
  transform: scale(0.3) rotate(-20deg);
}

.image.show {
  opacity: 1;
  transform: none;
}

.text-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-wrap {
  max-height: 2.2rem;
  overflow: hidden;
  margin-bottom: 2.5rem;
}

.text-group {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(0);
  transition: 0.5s;
}

.text-group h2 {
  line-height: 2.2rem;
  font-weight: 600;
  font-size: 1.6rem;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullets span {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #aaa;
  margin: 0 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.bullets span.active {
  width: 1.1rem;
  background-color: #151111;
  border-radius: 1rem;
}

.cityflat_logo{
  display: none;
}

@media (max-width: 850px) {
  .heading h4 {
    font-size: 1rem;
    margin-top: -9px;
    letter-spacing: -0.5px;
    color: #000;
    font-family: font-alethia-pro !important;
    margin-top: 10px;
    margin-bottom: 20px
  }

  .heading h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    font-weight: 400;
    font-family: "Spectral SC";
    color: #000;
  }

  .carousel_login {
    display: none;
  }


  .box {
    height: auto;
    max-width: 550px;
    overflow: hidden;
  }

  .inner-box {
    position: static;
    transform: none;
    width: revert;
    height: revert;
    padding: 2rem;
  }

  .forms-wrap {
    position: revert;
    width: 100%;
    height: auto;
  }

  .log-in-form {
    max-width: revert;
    padding: 1.5rem 2.5rem 2rem;
    transition: transform 0.8s ease-in-out, opacity 0.45s linear;
    margin-top: 0%;
  }

  .cityflat_logo{
    display: flex;
    align-items: center;
    justify-content: space-around
  }

  .cityflat_logo img {
    width: 40%;
    margin-right: 0.3rem;
  }
  .form.sign-up-form {
    transform: translateX(100%);
  }

  main.sign-up-mode form.log-in-form {
    transform: translateX(-100%);
  }

  main.sign-up-mode form.sign-up-form {
    transform: translateX(0%);
  }


  .images-wrapper {
    display: none;
  }

  .text-slider {
    width: 100%;
  }

  .go-home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

  
  }
  
  .go-home-space{
  height: 100px;
  position: relative;
  left: 25%;
  }
}

.separators {
  margin: 5% 10% 0% 10%;
}
.seperator {
  width: 40%;
  display: inline-block;
}

.left {
  float: left;
}
.right {
float: right;
}

.social-container {
  margin-top: 2%;
  padding: 15px 10px;
  justify-content: center;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

span{
  font-size: 0.7rem;
  font-family: font-alethia-pro;
}

@media (max-width: 530px) {
  main {
    padding: 1rem;
  }

  .box {
    border-radius: 2rem;
  }

  .inner-box {
    padding: 1rem;
  }

  .carousel_login {
    display: none;
  }

  .text-wrap {
    margin-bottom: 1rem;
  }

  .text-group h2 {
    font-size: 1.2rem;
  }

  .log-in-form {
    padding: 1rem 2rem 1.5rem;
  }
}

@media (max-width: 280px) {
  .heading h4 {
    font-size: 0.6rem;
    margin-top: -9px;
    letter-spacing: -0.5px;
    color: #000;
    font-family: font-alethia-pro !important;
    margin-top: 10px;
    margin-bottom: 20px
  }

  .heading h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    font-weight: 400;
    font-family: "Spectral SC";
    color: #000;
  }

  .carousel_login {
    display: none;
  }


  .box {
    height: auto;
    max-width: 550px;
    overflow: hidden;
  }

  .inner-box {
    position: static;
    transform: none;
    width: revert;
    height: revert;
    padding: 0.5rem;
  }

  .heading {
    margin: 2rem 0;
  }

  form.sign-up-form {
    transform: translateX(100%);
  }

  main.sign-up-mode form.log-in-form {
    transform: translateX(-100%);
  }

  main.sign-up-mode form.sign-up-form {
    transform: translateX(0%);
  }

}