a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

a,
a:hover {
    text-decoration: none !important;
}

.footer__page {
    min-height: 100%;
    padding-bottom: 3%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 3%;
    padding-left: 3%;
    font-family: "Spectral SC";
    background-color: #D7D7D7;
}

.content {
    height: 70vh;
}

.footer__content {
    padding: 7rem 0;
    color: #938d8d;
}

.footer__content .subscribe {
    position: relative;
    
}

.footer__content .subscribe .form-control {
    background-color: #000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 10px;
    height: 50px;
    padding-left: 30px;
    padding-right: 130px;
    border: none;
    color: #000;
}

.footer__content .subscribe .form-control::-webkit-input-placeholder {
    color: #b3b3b3;
    font-size: 14px;
    font-family: font-alethia-pro;
}

.footer__content .subscribe .form-control::-moz-placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.footer__content .subscribe .form-control:-ms-input-placeholder {
    color: #b3b3b3;
    font-size: 14px;
    font-family: font-alethia-pro;
}

.footer__content .subscribe .form-control:-moz-placeholder {
    color: #b3b3b3;
    font-size: 14px;
    font-family: font-alethia-pro;
}

.footer__content .subscribe .btn-submit {
    background: #fff;
    height: 40px;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    font-family: font-alethia-pro;
    top: 5px;
    right: -30px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.footer__content h3 {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Lexend Exa', sans-serif;
    color: #000;
    margin-bottom: 20px;
}

.footer_row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  
}

.footer__content .nav-links li {
    display: block;
    margin-bottom: 10px;
    
}

.footer__content .nav-links li a {
    color: #929191;
    font-family: font-alethia-pro;
}

.footer__content .nav-links li a:hover {
    color: #000;
}

.footer__content .social li {
    display: inline-block;
}

.footer__content .social li a {
    display: inline-block;
    padding: 10px;
    color: #9d9d9d;
}

.footer__content .social li a:hover {
    color: #000;
}

.footer_row  {
    text-align: left !important;
}

.list-unstyled{
    text-align: left !important;
}

@media only screen and (max-width: 480px) {
    .footer__content .subscribe .btn-submit {
        background: #fff;
        height: 40px;
        border-radius: 10px;
        padding-left: 30px;
        padding-right: 30px;
        color: #000;
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        font-family: font-alethia-pro;
        top: 5px;
        right: -7px;
        margin-right: 5px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }

    .footer__content .subscribe .form-control {
        background-color: #000;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 10px;
        height: 50px;
        padding-left: 20px;
        padding-right: 110px;
        border: none;
        color: #000;
        width: 95%;
    }

    .footer_row  {
        text-align: center !important;
    }
    
    .list-unstyled{
        text-align: center !important;
    }
    }

    /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer_row  {
        text-align: center !important;
    }
    
    .list-unstyled{
        text-align: center !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .footer_row  {
        text-align: center !important;
    }
    
    .list-unstyled{
        text-align: center !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .footer_row  {
        text-align: center !important;
    }
    
    .list-unstyled{
        text-align: center !important;
    }

    .footer_col{
        flex: auto !important;
    }
}

@media only screen and (max-width: 820px) {
    .footer_row  {
        text-align: center !important;
    }
    
    .list-unstyled{
        text-align: center !important;
    }

    .footer_col{
        flex: auto !important;
    }
}

