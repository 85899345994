.walkthrough-container {
  background-color: #d7d7d7;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.walkthrough-content {
  padding: 20px;
  border-radius: 5px;
}

.step-content {
  margin-bottom: 20px;
}

.step-text {
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step-btn {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.step-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.step-btn:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .step-img {
    max-height: 50vh;
  }
  .step-text {
    font-size: 1.3rem;
  }
}

@media (min-width: 768px) {
  .step-img {
    max-height: 60vh;
  }
  .step-text {
    font-size: 2rem;
  }
}

.steps-nav-cont {
  background-color: #000;
  height: 4rem;
}
