.payment_page {
  background-color: black;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.payment_title {
  text-align: left;
  color: white;
  font-family: "Lexend Mega", sans-serif;
}

.payment__body {
  padding: 3%;
}

.card__body__payment {
  text-align: center;
  padding: 5%;
  border-radius: 15px;
  border: 1px solid #d7d7d7;
  background-color:beige ;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.service_img {
  width: 45px;
  height: 45px;
}

.services {
  justify-content: center;
  margin-top: 10%;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
}

.service_title {
  font-size: 10px !important;
  font-weight: 800;
  font-family: "Lexend Mega", sans-serif;
}

.apartment_picture {
  width: 250px;
  height: 150px;
  margin-top: 3%;
  margin-bottom: 5%;
}

.content_col_payment {
  position: relative;
}

.card_infos_payment {
  text-align: center;
  padding: 5%;
}

.card__body__payment h4 {
  font-family: "Lexend Mega", sans-serif;
  margin-bottom: 7%;
}

.card__body__payment h5 {
  font-family: font-alethia-pro;
  margin-bottom: 7%;
}

.card__body__payment hr {
  margin: 10%;
}

.card__body {
  background-color: beige;
  border-radius: 15px;
  border: 1px solid #d7d7d7;
  padding: 3%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.card__body h4 {
  font-family: "Lexend Mega", sans-serif;
}

.card__body strong {
  font-family: font-alethia-pro;
}
.card__body p {
  font-family: font-alethia-pro;
}

.form_col {
  padding: 3%;
}

.form-select {
  border-radius: 0 !important;
}

.cancellation_policy {
  font-family: "Lexend Mega", sans-serif;
  font-size: 20px !important;
}

.align_left {
  text-align: left;
}

.align_right {
  text-align: right;
  padding: 3% 2% 2% 2%;
}

.no__margin {
  margin-bottom: 0% !important;
}

.custom-confirm-button {
  background-color: black !important;
  font-family: font-alethia-pro !important;
  margin: 1%;
}

.payment_col {
  display: flex;
  justify-content: center; /* horizontal center */
  align-items: center;
}
